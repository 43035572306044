import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './services/serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// If I want Server Side Rendering for frontpage, use ReactDOMServer.renderToString()
// here in a server-side version and cache the html somewhere, then in a client-side
// version of this file use hydrate() to make it dynamic again.

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
